<template>
  <div class="grid gap-6">
    <ErrorAlert v-if="formError" :text="formError" />
    <TextField
      v-model="name"
      :label="translations.nameLabel"
      :placeholder="translations.namePlaceholder"
      :focus="true"
      @input="update"
    />
    <TextArea
      v-model="description"
      :label="translations.descriptionLabel"
      :placeholder="translations.descriptionPlaceholder"
      :maxlength="280"
      :counter="true"
      @input="update"
    />

    <SelectField
      v-model="courseUuid"
      :label="translations.courseLabel"
      @input="update"
    >
      <option
        v-for="dishCourse in dishCourses"
        :key="dishCourse.uuid"
        :value="dishCourse.uuid"
        :selected="courseUuid === dishCourse.uuid"
      >
        {{ dishCourse.name }}
      </option>
    </SelectField>

    <div class="col-gap-4 row-gap-6 grid md:grid-cols-2">
      <TextField
        v-model="estimatedPrice"
        :label="translations.estimatedPriceLabel"
        :placeholder="translations.estimatedPricePlaceholder"
        type="number"
        step="0.01"
        @input="update"
      />
      <TextField
        v-model="productMix"
        :label="translations.productMixLabel"
        :placeholder="translations.productMixPlaceholder"
        step="0.01"
        type="number"
        @input="update"
      />
    </div>
    <SelectField
      v-model="duration"
      :label="translations.durationLabel"
      @input="update"
    >
      <option value="-1" selected disabled>
        {{ translations.durationPlaceholder }}
      </option>
      <option
        v-for="durationOption in durationOptions"
        :key="durationOption.index"
        :value="durationOption"
        :selected="durationOption === duration"
      >
        {{ durationOption }}
      </option>
    </SelectField>

    <CoverImageUpload
      :current-image="currentDishProposal.coverImage"
      :is-uploading-cover-image="isUploadingCoverImage"
      :cover-image-upload-error="coverImageUploadError"
      :upload-cover-image="uploadCoverImage"
      :remove-cover-image="removeCoverImage"
      class="lg:h-64 xl:mb-0"
    />

    <div class="text-sm text-neutral-400">
      {{ translations.editNote }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CoverImageUpload from "@/components/menus/CoverImageUpload";
import ErrorAlert from "@/components/common/ErrorAlert";

export default {
  components: {
    ErrorAlert,
    CoverImageUpload,
  },

  props: {
    currentDishProposal: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: "",
          description: "",
          course: {
            uuid: null,
          },
          coverImage: {
            uuid: null,
          },
          estimatedPrice: null,
          productMix: null,
          duration: "",
        };
      },
    },
  },

  data() {
    return {
      name: this.currentDishProposal.name,
      description: this.currentDishProposal.description,
      courseUuid: this.currentDishProposal.course.uuid,
      coverImageFileUuid: this.currentDishProposal.coverImage
        ? this.currentDishProposal.coverImage.uuid
        : null,
      estimatedPrice: this.currentDishProposal.estimatedPrice,
      productMix: this.currentDishProposal.productMix,
      duration: this.currentDishProposal.duration,

      translations: {
        nameLabel: this.$t("components.menus.dishNameLabel"),
        namePlaceholder: this.$t("components.menus.dishNamePlaceholder"),
        descriptionLabel: this.$t("components.menus.dishDescriptionLabel"),
        descriptionPlaceholder: this.$t(
          "components.menus.dishDescriptionPlaceholder",
        ),
        courseLabel: this.$t("components.menus.dishCourseLabel"),
        estimatedPriceLabel: this.$t("components.menus.estimatedPriceLabel"),
        estimatedPricePlaceholder: this.$t(
          "components.menus.estimatedPricePlaceholder",
        ),
        productMixLabel: this.$t("components.menus.productMixLabel"),
        productMixPlaceholder: this.$t(
          "components.menus.productMixPlaceholder",
        ),
        durationLabel: this.$t("components.menus.durationLabel"),
        durationPlaceholder: this.$t("components.menus.durationPlaceholder"),
        editNote: this.$t("components.menus.editNote"),
      },

      durationOptions: [
        "2 weeks",
        "1 month",
        "3 months",
        "6 months",
        "1 year +",
      ],
    };
  },

  computed: {
    ...mapGetters({
      dishCourses: "dish/dishCourses",
      formError: "dishProposalForm/formError",
      isUploadingCoverImage: "dishProposalForm/isUploadingCoverImage",
      coverImageUploadError: "dishProposalForm/coverImageUploadError",
    }),
  },

  created() {
    this.update();

    if (this.currentDishProposal.coverImage) {
      this.updateCoverImageFileUuid(this.currentDishProposal.coverImage.uuid);
    }
  },

  methods: {
    ...mapActions("dishProposalForm", [
      "updateBasicInformation",
      "uploadCoverImage",
      "removeCoverImage",
      "updateCoverImageFileUuid",
    ]),

    update() {
      this.updateBasicInformation({
        name: this.name,
        description: this.description,
        courseUuid: this.courseUuid,
        estimatedPrice: this.estimatedPrice,
        productMix: this.productMix,
        duration: this.duration,
      });
    },
  },
};
</script>
