<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{ $t("components.menus.proposeDish") }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <ModalSideContent class="flex-grow overflow-y-scroll">
        <ProposalForm />
      </ModalSideContent>

      <ModalSideActions class="justify-end">
        <BaseButton
          :text="$t(`global.cancel`)"
          size="medium"
          variant="outline"
          @buttonClick="closeModal"
        ></BaseButton>

        <BaseButton
          :text="$t(`components.menus.submitToCommunity`)"
          size="medium"
          variant="fill"
          class="ml-2"
          @buttonClick="saveAndCloseModal"
        >
        </BaseButton>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";
import ModalSideActions from "@/components/common/ModalSideActions";
import ProposalForm from "@/components/menus/proposal/ProposalForm";

export default {
  components: {
    ModalSide,
    ModalSideContent,
    ModalSideActions,
    ProposalForm,
  },

  computed: {
    ...mapGetters({
      formError: "dishProposalForm/formError",
    }),
  },

  methods: {
    ...mapActions("dishProposalForm", ["createDishProposal"]),

    async saveAndCloseModal() {
      await this.createDishProposal();

      if (!this.formError) {
        this.closeModal();
      }
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
